import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainApp from "./features/MainApp";
import { AuthProvider } from "./components/auth/AuthContext";
import gameStore from "./store/store";
import Alert from "./components/Alert";

const App = () => {
  useEffect(() => {
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.ready();
      window.Telegram.WebApp.expand();
    }
  }, []);

  return (
    <BrowserRouter>
      <AuthProvider>
        <Alert />
        <MainApp />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
