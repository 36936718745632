import React from 'react';
import { observer } from "mobx-react-lite";
import gameStore from "../store/store";
import Button from "./Button";
import icons from '../assets/icons';

const Keyboard = observer(() => {
  // Буквы по алфавиту, разделенные на ряды
  const rows = [
    ['а', 'б', 'в', 'г', 'д', 'е', 'ё', 'ж', 'з', 'и'],
    ['й', 'к', 'л', 'м', 'н', 'о', 'п', 'р', 'с', 'т'],
    ['у', 'ф', 'х', 'ц', 'ч', 'ш', 'щ', 'ъ', 'ы', 'ь'],
    ['э', 'ю', 'я']
  ];

  const { letterStates, currentGuess, gameOver, invalidGuess } = gameStore.currentGame;

  const getKeyClassName = (letter) => {
    const baseClass = "w-[34px] h-[34px] rounded-lg text-lg uppercase flex items-center justify-center";
    
    // Проверяем, использовалась ли буква
    const isUsed = letterStates[letter] !== undefined;
    
    switch (letterStates[letter]) {
      case 'correct':
        return `${baseClass} bg-[#C9F456] border-[#247A0A] text-[#247A0A] shadow-[0px_2px_0px_0px_#247A0A] font-bold`;
      case 'present':
        return `${baseClass} bg-[#FFF65C] border-[#804D42] text-[#804D42] shadow-[0px_2px_0px_0px_#804D42] font-bold`;
      case 'absent':
        return `${baseClass} bg-[#F6F3FC] border-[#623FA5] text-[#623FA5] shadow-[0px_2px_0px_0px_#623FA5] font-bold`;
      default:
        return isUsed 
          ? `${baseClass} bg-[#F6F3FC] border-[#623FA5] text-[#623FA5] shadow-[0px_2px_0px_0px_#623FA5] font-bold`
          : `${baseClass} bg-[#F6F3FCA3] border-[#623FA5] text-[#623FA5]`;
    }
  };

  const handleKeyClick = (letter) => {
    if (!gameOver && currentGuess.length < 5) {
      gameStore.updateCurrentGuess(letter);
    }
  };

  const handleEnter = () => {
    if (!gameOver && currentGuess.length === 5) {
      gameStore.submitGuess();
    }
  };

  const handleDelete = () => {
    if (!gameOver && currentGuess.length > 0) {
      gameStore.deleteLastLetter();
    }
  };

  // Проверяем, можно ли использовать кнопки
  const canSubmit = !gameOver && currentGuess.length === 5;
  const canDelete = !gameOver && currentGuess.length > 0;

  const getSubmitButtonStyles = () => {
    const baseClass = "flex-1 h-[50px] rounded-lg font-bold";
    
    // Если слово не введено полностью
    if (currentGuess.length !== 5) {
      return `${baseClass} bg-[#F6F3FCA3] text-[#623FA5] opacity-50`;
    }
    
    // Если слово введено, но это не валидное слово
    if (invalidGuess) {
      return `${baseClass} bg-[#F335B4] text-[#F6F3FC] shadow-[0px_4px_0px_0px_#AF0C79]`;
    }
    
    // Стандартное состояние (слово введено полностью)
    return `${baseClass} bg-[#C9F456] text-[#247A0A] shadow-[0px_4px_0px_0px_#247A0A]`;
  };

  const getSubmitTextStyles = () => {
    const baseClass = "text-base font-bold uppercase";
    
    if (currentGuess.length !== 5) {
      return `${baseClass} text-[#623FA5]`;
    }
    
    if (invalidGuess) {
      return `${baseClass} text-[#F6F3FC]`;
    }
    
    return `${baseClass} text-[#247A0A]`;
  };

  const getDeleteButtonStyles = () => {
    const baseClass = "w-[70px] h-[50px] rounded-lg font-bold";
    
    // Если нечего стирать
    if (!canDelete) {
      return `${baseClass} bg-[#F6F3FCA3] text-[#623FA5] opacity-50`;
    }
    
    // Стандартное состояние
    return `${baseClass} bg-[#F6F3FC] text-[#623FA5] shadow-[0px_4px_0px_0px_#623FA5]`;
  };

  return (
    <div className="flex flex-col items-center w-full">
      {rows.map((row, rowIndex) => (
        <div key={rowIndex} className="flex gap-1 mb-1 justify-center w-full max-w-md">
          {row.map((letter) => (
            <button
              key={letter}
              className={getKeyClassName(letter)}
              onClick={() => handleKeyClick(letter)}
            >
              {letter.toUpperCase()}
            </button>
          ))}
        </div>
      ))}
      <div className="flex gap-1 justify-center w-full max-w-md px-2 py-4">
        <Button
          text={invalidGuess ? "не слово" : "Отправить"}
          className={getSubmitButtonStyles()}
          textClassName={getSubmitTextStyles()}
          onClick={handleEnter}
          disabled={currentGuess.length !== 5}
        />
        <Button
          text=""
          icon={icons.Del}
          className={getDeleteButtonStyles()}
          onClick={handleDelete}
          disabled={!canDelete}
        />
      </div>
    </div>
  );
});

export default Keyboard;
