import { ReactComponent as Home } from "./home.svg";
import { ReactComponent as HomeFill } from "./homeFill.svg";
import { ReactComponent as Friends } from "./friends.svg";
import { ReactComponent as FriendsFill } from "./friendsFill.svg";
import { ReactComponent as Shop } from "./shop.svg";
import { ReactComponent as ShopFill } from "./shopFill.svg";
import { ReactComponent as Stats } from "./stats.svg";
import { ReactComponent as StatsFill } from "./statsFill.svg";
import { ReactComponent as Ticket } from "./ticket.svg";
import { ReactComponent as Bg } from "./back.svg";
import { ReactComponent as Tg } from "./tg.svg";
import { ReactComponent as Copy } from "./copy.svg";
import { ReactComponent as Ad } from "./ad.svg";
import { ReactComponent as Stars } from "./stars.svg";
import { ReactComponent as Clubs } from "./clubs.svg";
import { ReactComponent as Diamonds } from "./diamonds.svg";
import { ReactComponent as Hearts } from "./hearts.svg";
import { ReactComponent as Spades } from "./spades.svg";
import { ReactComponent as Reload } from "./reload.svg";
import { ReactComponent as Settings } from "./settings.svg";
import { ReactComponent as Del } from "./del.svg";
import { ReactComponent as SoundOn } from "./onsound.svg";
import { ReactComponent as SoundOff } from "./offsound.svg";

export default {
  Home,
  HomeFill,
  Friends,
  FriendsFill,
  Shop,
  ShopFill,
  Stats,
  StatsFill,
  Ticket,
  Bg,
  Tg,
  Copy,
  Ad,
  Stars,
  Clubs,
  Diamonds,
  Hearts,
  Spades,
  Reload,
  Settings,
  Del,
  SoundOn,
  SoundOff
};
