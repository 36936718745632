import React from 'react';
import { observer } from "mobx-react-lite";
import gameStore from "../store/store";
import Modal from "./Modal";
import { useNavigate } from "react-router-dom";
import ButtonLink from './ButtonLink';
import icons from '../assets/icons';
import TicketCounter from './TicketCounter';
import winImage from '../assets/img/win.png';

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
};

// VictoryModal.jsx
const VictoryModal = observer(({ isOpen, onClose }) => {
  const navigate = useNavigate();

  const handleNewGame = () => {
    if (gameStore.tickets > 0) {
      gameStore.startNewGame();
      onClose();
    }
  };

  const handleExitToMenu = () => {
    onClose();
    navigate("/");
  };

  return (
    <>
    <Modal
      isOpen={isOpen}
      title="ПОБЕДА!"
      hasCloseButton={false}
      closeOnClickOutside={false}
      openConfetti={true}
      backgroundImage={winImage}
    >
      <div className="p-4 pt-0 text-center relative z-10">

        {/* Статистика */}
        <div className="bg-[#6641A97D] rounded-lg p-4 mb-6 shadow-md text-center" style={{ border: "2px solid #623FA5" }}>
          <div
            className="flex justify-between items-center text-[#F6F3FC] mb-3" style={{ textShadow: "0px 4px 0px #623FA5" }}
          >
            <div className="text-xl font-bold" >ОЧКИ</div>
            <div className="text-2xl font-bold">{gameStore.currentGame.score}</div>
          </div>

          <div className="h-px bg-[#FFFFFF] opacity-25 my-3"></div> {/* Разделитель */}

          <div className="flex justify-between items-center text-[#F6F3FC]" style={{ textShadow: "0px 4px 0px #623FA5" }}>
            <div className="text-xl font-bold">ОСТАЛОСЬ ПОПЫТОК</div>
            <div className="text-2xl font-bold">{6 - gameStore.currentGame.currentRow}</div>
          </div>
        </div>

        {/* Кнопки */}
        <div className="pt-0 flex flex-col gap-3">
          <ButtonLink
            to="/"
            text="Выйти в меню"
            className="bg-[#F6F3FC] text-[#6641A9] py-2 mx-3.5"
            style={{
              boxShadow: "0px 4px 0px 0px #4F3285",
            }}
            onClick={handleExitToMenu}
          />

          {gameStore.tickets > 0 ? (
            <ButtonLink
              icon={icons.Ticket}
              onClick={handleNewGame}
              text="Новая игра"
              afterText="x1"
              className="bg-[#F6F3FC] text-[#6641A9] py-2 mx-12"
              iconClassName="fill-[#6641A9]"
              style={{
                boxShadow: "0px 4px 0px 0px #623FA5",
              }}
            />
          ) : (
            <ButtonLink
              icon={icons.Ticket}
              text="Недостаточно"
              to="/shop"
              className="bg-[#F335B4] text-[#F6F3FC] py-2 mx-8"
              iconClassName="fill-[#F6F3FC]"
              style={{
                boxShadow: "0px 4px 0px 0px #AF0C79",
              }}
              disabled
            />
          )}
        </div>
      </div>
    </Modal>
    </>
  );
});

export default VictoryModal;