import React from 'react';
import Modal from './Modal';
import TicketCounter from './TicketCounter';

const HowToPlayModal = ({ isOpen, onClose }) => {
  const baseClass = "w-[35px] h-[35px] flex items-center justify-center text-xl font-bold rounded-lg";
  return (
    <>
    <Modal isOpen={isOpen} onClose={onClose} closeOnClickOutside={false} title="КАК ИГРАТЬ">
      <div className="px-4 text-left overflow-y-auto" style={{ maxHeight: "75vh" }}>
        <div className="mb-4">
          <div className="flex">
            <div className="flex-none text-[#F6F3FCBA] rounded-lg font-bold border border-[#F6F3FC33] w-8 h-8 flex items-center justify-center mr-2">1</div>
            <div>
              <div className="flex-grow text-md font-bold">Напишите любое слово из 5 букв</div>
              <div className='flex flex-col gap-2'>
                <span className='text-md'>К примеру:</span>
                <div className="flex text-xl gap-1">
                  <span className={`${baseClass} bg-[#F6F3FC] text-[#6641A9]`}>Л</span>
                  <span className={`${baseClass} bg-[#F6F3FC] text-[#6641A9]`}>И</span>
                  <span className={`${baseClass} bg-[#F6F3FC] text-[#6641A9]`}>М</span>
                  <span className={`${baseClass} bg-[#F6F3FC] text-[#6641A9]`}>О</span>
                  <span className={`${baseClass} bg-[#F6F3FC] text-[#6641A9]`}>Н</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-px bg-white opacity-25 my-3"></div>
        <div className="mb-4">
          <div className="flex">
            <div className="flex-none text-[#F6F3FCBA] rounded-lg font-bold border border-[#F6F3FC33] w-8 h-8 flex items-center justify-center mr-2">2</div>
            <div className='flex flex-col gap-2'>
              <div className="flex-grow text-md font-bold">
                После того, как вы отправите его, мы покажем какие буквы есть в загаданном слове
              </div>
              <div className="flex text-xl gap-1">
                <span className={`${baseClass} bg-[#F6F3FC] text-[#623FA5] shadow-[0px_3px_0px_0px_#623FA5]`}>Л</span>
                <span className={`${baseClass} bg-[#F6F3FC] text-[#623FA5] shadow-[0px_3px_0px_0px_#623FA5]`}>И</span>
                <span className={`${baseClass} bg-[#FFF65C] text-[#804D42] shadow-[0px_3px_0px_0px_#804D42]`}>М</span>
                <span className={`${baseClass} bg-[#C9F456] text-[#247A0A] shadow-[0px_3px_0px_0px_#247A0A]`}>О</span>
                <span className={`${baseClass} bg-[#C9F456] text-[#247A0A] shadow-[0px_3px_0px_0px_#247A0A]`}>Н</span>
              </div>
              <div className='flex flex-col gap-0.5 mt-2'>
                <div className="flex">
                  <div className='flex-none w-[30px] h-[30px] rounded-lg bg-[#C9F456] text-[#247A0A] shadow-[0px_2px_0px_0px_#247A0A]'></div>
                  <div className='grid flex-none w-[35px] h-[30px] items-center px-2'>
                    <div className='bg-[#F6F3FCBA] w-full h-[1px] items-center'></div>
                  </div>
                  <div className="text-md">Буква есть в слове и её положение верное</div>
                </div>
                <div className="flex">
                  <div className='flex-none w-[30px] h-[30px] rounded-lg bg-[#FFF65C] text-[#804D42] shadow-[0px_2px_0px_0px_#804D42]'></div>
                  <div className='grid flex-none w-[35px] h-[30px] items-center px-2'>
                    <div className='bg-[#F6F3FCBA] w-full h-[1px] items-center'></div>
                  </div>
                  <div className="text-md">Буква есть в слове, но ее положение неверное</div>
                </div>
                <div className="flex">
                  <div className='flex-none w-[30px] h-[30px] bg-[#C9F456] rounded-lg bg-[#F6F3FC] text-[#623FA5] shadow-[0px_2px_0px_0px_#623FA5]'></div>
                  <div className='grid flex-none w-[35px] h-[30px] items-center px-2'>
                    <div className='bg-[#F6F3FCBA] w-full h-[1px] items-center'></div>
                  </div>
                  <div className="text-md">Буквы нет в слове</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-px bg-white opacity-25 my-3"></div>
        <div className="mb-4">
          <div className="flex">
            <div className="flex-none text-[#F6F3FCBA] rounded-lg font-bold border border-[#F6F3FC33] w-8 h-8 flex items-center justify-center mr-2">3</div>
            <div className='flex flex-col gap-2'>
              <div className="flex-grow text-md font-bold">У вас есть 6 попыток, чтобы отгадать слово.<br />За каждую неиспользованную попытку вы получаете бонусные очки</div>
              <div className="flex text-xl gap-1">
                <span className={`${baseClass} bg-[#C9F456] text-[#247A0A] shadow-[0px_3px_0px_0px_#247A0A]`}>М</span>
                <span className={`${baseClass} bg-[#C9F456] text-[#247A0A] shadow-[0px_3px_0px_0px_#247A0A]`}>А</span>
                <span className={`${baseClass} bg-[#C9F456] text-[#247A0A] shadow-[0px_3px_0px_0px_#247A0A]`}>С</span>
                <span className={`${baseClass} bg-[#C9F456] text-[#247A0A] shadow-[0px_3px_0px_0px_#247A0A]`}>О</span>
                <span className={`${baseClass} bg-[#C9F456] text-[#247A0A] shadow-[0px_3px_0px_0px_#247A0A]`}>Н</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-4">
        <button onClick={onClose} className="w-full bg-[#C9F456] text-[#247A0A] px-6 py-2 rounded-lg shadow-[0px_3px_0px_0px_#247A0A] font-bold">
          ПОНЯТНО
        </button>
      </div>
      </div>
    </Modal>
    </>
  );
};

export default HowToPlayModal;
