import React from "react";
import { observer } from "mobx-react-lite";
import gameStore from "../store/store";
import icons from "../assets/icons";
import Icon from "./Icon";

const TicketCounter = observer(() => {
  const { tickets } = gameStore;

  return (
    <div
      className="absolute top-0 left-1/2 transform -translate-x-1/2 rounded-b-xl shadow-custom px-10 py-2 shadow-lg flex items-center gap-2"
      style={{
        "background-color": tickets > 0 ? "#623FA5A8" : "#F335B4D9",
        border: tickets > 0 ? "2px solid #623FA5" : "1px solid #AF0C79",
        zIndex: 150,
      }}
    >
      {/* Картинка */}
      <Icon
        icon={icons.Ticket}
        activeIcon={icons.Ticket}
        className="w-6 h-6 fill-[#F6F3FC]"
        style={{
          filter: tickets > 0 ? "drop-shadow(0px 4px 0px #623FA5)" : "drop-shadow(0px 4px 0px #AF0C79)",
        }}
      />
      {/* Текст */}
      <span
        style={{
          textShadow: tickets > 0 ? "0px 4px 0px #623FA5" : "0px 4px 0px #AF0C79",
        }} 
        className="text-[#F6F3FC] font-bold text-xl"
      >
        {tickets}/100
      </span>
    </div>
  );
});

export default TicketCounter;