import React, { useEffect, useState } from 'react';
import { observer } from "mobx-react-lite";
import gameStore from "../store/store";
import VictoryModal from './VictoryModal';
import DefeatModal from './DefeatModal';

const WordGrid = observer(({ onTutorialOpen }) => {
  const { guesses = [], currentGuess = '', currentRow = 0, invalidGuess = false } = gameStore.currentGame || {};
  const [showVictoryModal, setShowVictoryModal] = useState(false);
  const [showDefeatModal, setShowDefeatModal] = useState(false);
  const [shakeRow, setShakeRow] = useState(false);

  useEffect(() => {
    if (gameStore.currentGame.gameOver) {
      // Проверяем, является ли последнее слово правильным
      const lastGuess = guesses[guesses.length - 1]?.word;
      const isVictory = lastGuess === gameStore.currentGame.word;
      
      // Показываем соответствующее модальное окно
      if (isVictory) {
        setShowVictoryModal(true);
      } else {
        setShowDefeatModal(true);
      }
    }
  }, [gameStore.currentGame.gameOver, guesses]);

  useEffect(() => {
    if (invalidGuess) {
      setShakeRow(true);
      const timer = setTimeout(() => {
        setShakeRow(false);
      }, 500); // Длительность анимации
      return () => clearTimeout(timer);
    }
  }, [invalidGuess]);

  // Создаем сетку 6x5 для слов
  const grid = Array(6).fill().map((_, rowIndex) => {
    if (rowIndex < guesses.length) {
      // Заполненные строки
      return guesses[rowIndex].word.split('');
    } else if (rowIndex === currentRow) {
      // Текущая строка
      const row = currentGuess.split('');
      while (row.length < 5) row.push(' ');
      return row;
    } else {
      // Пустые строки
      return Array(5).fill(' ');
    }
  });

  const getLetterClassName = (rowIndex, colIndex) => {
    const baseClass = "w-[52px] h-[52px] flex items-center justify-center text-3xl font-bold rounded-lg";
    
    if (rowIndex < guesses.length) {
      const letterState = guesses[rowIndex].letterStates[colIndex];
      switch (letterState) {
        case 'correct':
          return `${baseClass} bg-[#C9F456] border-[#247A0A] text-[#247A0A] shadow-[0px_4px_0px_0px_#247A0A]`;
        case 'present':
          return `${baseClass} bg-[#FFF65C] border-[#804D42] text-[#804D42] shadow-[0px_4px_0px_0px_#804D42]`;
        case 'absent':
          return `${baseClass} bg-[#F6F3FC] border-[#623FA5] text-[#623FA5] shadow-[0px_4px_0px_0px_#623FA5]`;
        default:
          return `${baseClass} bg-[#F6F3FC] border-[#623FA5] text-[#623FA5]`;
      }
    } else if (rowIndex === currentRow) {
      const hasLetter = grid[rowIndex][colIndex] !== ' ';
      return hasLetter 
        ? `${baseClass} border-2 bg-[#F6F3FC] border-[#623FA5] text-[#623FA5] ${shakeRow ? 'animate-shake' : ''}`
        : `${baseClass} border-2 bg-[#F6F3FCA3] border-[#623FA5] text-[#623FA5] ${shakeRow ? 'animate-shake' : ''}`;
    } else {
      return `${baseClass} border-0 bg-[#623FA57D] text-[#623FA5]`;
    }
  };

  return (
    <div className="w-full flex flex-col items-center">
      <div className="grid grid-rows-6 gap-1.5 w-fit">
        <div className="flex items-center justify-between w-full">
        <span className="text-[#F6F3FC] text-lg">Введите слово</span>
        <button
          onClick={onTutorialOpen}
          className="w-8 h-8 rounded-lg flex items-center justify-center"
          style={{
            border: "2px solid #F6F3FC",
          }}
        >
          <span className="text-[#F6F3FC] font-bold text-lg">?</span>
        </button>
      </div>
        {grid.map((row, rowIndex) => (
          <div key={rowIndex} className="flex gap-1.5 justify-center">
            {row.map((letter, colIndex) => (
              <div
                key={`${rowIndex}-${colIndex}`}
                className={getLetterClassName(rowIndex, colIndex)}
              >
                {letter !== ' ' ? letter.toUpperCase() : ''}
              </div>
            ))}
          </div>
        ))}
      </div>
      <VictoryModal 
        isOpen={showVictoryModal} 
        onClose={() => setShowVictoryModal(false)} 
      />
      <DefeatModal 
        isOpen={showDefeatModal} 
        onClose={() => setShowDefeatModal(false)} 
      />
    </div>
  );
});

export default WordGrid;
